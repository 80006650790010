const theme = {
  colorSchemes: {
    light: {
      colors: {
        text: {
          disabled: "#959da6",
        },
      },
    },
  },
};

export default theme;
